.teamWrapper {
  width: 100%;
  max-width: 350px;
  margin: 0px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  position: relative;
}

.teamContent {
  width: 100%;
}

.notesWrapper {
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  width: 100%;
  height: 300px;
  padding-bottom: 20px;
}

@media (max-width: 1024px) {
  .teamWrapper {
    width: 100%;
    margin: 0;
    padding: 0 8px;
  }
} 