.content {
  padding: 30px;
  line-height: 1.6;
  color: rgba(197, 197, 197, 0.85)
}

.content p {
  margin-bottom: 20px;
}

.content b {
  color: rgba(255, 255, 255, 1);
  font-weight: 600;
}

.modal {
  margin: 50px auto;
  max-width: 600px;
}
