.button
{
  min-height: 36px;
  min-width: 36px;
  white-space: nowrap;
  border: 0 !important;
  display: inline-block;
  color: rgb(160, 160, 160) !important;
}

.dimmedButton {
  min-height: 36px;
  min-width: 36px;
  white-space: nowrap;
  border: 0 !important;
  display: inline-block;
  color: rgb(107, 107, 107) !important;
}

.disabledButton {
    min-height: 36px;
    min-width: 36px;
    white-space: nowrap;
    border: 0 !important;
    display: inline-block;
    color: rgb(66, 66, 66) !important;
  }

.highlightButton {
  min-height: 36px;
  min-width: 36px;
  white-space: nowrap;
  border: 0 !important;
  display: inline-block;
  color: lightgreen !important;
}

.roundPickerButton {
  font-size: 12px;
  min-height: 36px;
  min-width: 36px;
  border-radius: 6px;
  white-space: nowrap;
  text-align: center;
  border: 0 !important;
  padding: 0;
}

.roundPickerSplitterButton {
  margin-left: 24px;
}

.buttonActivated {
  border: 0 !important;
  color: rgb(209, 209, 209) !important;
  background-color: rgb(49, 49, 49) !important;
}

.button:hover {
  border: 0 !important;
  color: rgb(255, 255, 255) !important;
  background-color: rgb(49, 49, 49);
}

.dimmedButton:hover {
  border: 0 !important;
  color: rgb(255, 255, 255) !important;
  background-color: rgb(49, 49, 49);
}

.dimmedButton:active {
  border: 0 !important;
  color: rgb(255, 255, 255) !important;
  background-color: rgb(49, 49, 49);
}

.highlightButton:hover {
  border: 0 !important;
  color: rgb(255, 255, 255) !important;
  background-color: rgb(49, 49, 49);
}

.colorButton {
  height: 31px;
  width: 31px;
  white-space: nowrap;
  display: inline-block;
}

.playButton {
  color: lightgreen !important;
  display: inline-block;
}