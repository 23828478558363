.wrapper {
  width: 100%;
}

.wrapperMinimal {
  width: 100%;
  padding: 4px;
}

.loadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #383838;
  background-color: rgba(255, 255, 255, 0.01);
  border-radius: 10px;
  gap: 10px;
  height: 310px;
}

.loadingText {
  color: #555;
  font-size: 1rem;
} 