.volumeMeterContainer {
  width: 14px;
  height: 100%;
  position: relative; /* Add position relative to center the bar within the container */
  cursor: pointer;
}

.volumeMeterBar {
  width: 14px;
  background: linear-gradient(to top, #633f5a 0%, #704766 50%, #633f5a 100%);
  position: absolute;
  top: 50%; /* Position the bar at the vertical center */
  left: 0;
  transform-origin: center; /* Set the transformation origin to the center */
  transform: translateY(-50%); /* Offset by 50% of the bar's height */
}

.muteIcon {
  position: absolute;
  top: 50%;
  left: 0;
  transform-origin: center;
  transform: translateY(-50%);
  color: #ff0000;
}