.timer {
    display: flex;
    justify-content: space-between; /* separates the elements */
    align-items: center;
    padding: 5px;
    padding-left: 15px;
    border: 2px dashed #464646; /* gray */
    border-radius: 10px;
    color: #888888; /* dark gray */
    font-family: Arial, sans-serif;
    margin-bottom: 10px;
  }
  
  .message {
    font-size: 1em;
    text-align: left;
  }
  
  .time {
    font-size: 1em;
    font-weight: bold;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: #55b86f; /* green */
    text-align: right;
  }
  
  .time_unit {
    font-size: 0.8em;
    color: #3e6648; /* green */
    margin-right: 9px;
    margin-left: 3px;
  }

  @media (max-width: 700px) {
    .timer {
      font-size: 0.8em;
    }
  
    .message {
      font-size: 0.8em;
    }
  
    .time {
      font-size: 0.8em;
    }
  
    .time_unit {
      font-size: 0.6em;
    }
  }