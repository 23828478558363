.playlistTable td {
  padding: 0px 5px;
  margin: 0;
  vertical-align: middle;
  min-height: 40px;
  height: 40px;
  color: #888;
  border-bottom: 1px solid #444;
}

.playlistTable tbody {
  border-top: 2px solid #5f5f5f !important;
}

.playlistTable th {
  padding: 5px 5px;
  margin: 0;
  color: #555;
}

.playlistName {
  cursor: pointer;
}

.editPlaylistHoverIcon {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.playlistName:hover .editPlaylistHoverIcon {
  opacity: 1;
}

.playlistTable td:first-child,
.playlistTable th:first-child {
  padding-left: 15px;
  width: 30px;
}

.playlistTable input[type="checkbox"] {
  cursor: pointer;
}