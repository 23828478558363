.modal {
    min-width: 700px;
}

.tableRowLight {
    background-color: #3a3a3a;
    /* Light background color */
}

.tableRowDark {
    background-color: #333333;
    /* Dark background color */
}

.tableRowMoney {
    background-color: #333333;
    /* Dark background color */
    line-height: 22px;
}

.upgradeButton {
    width: 50%;
    margin-left: 20px;
}

.centeredCell {
    text-align: center;
    /* Center align the contents */
    width: 110px;
    /* Set the desired width for the cells */
}

.preview {
    cursor: pointer;
    text-align: center;
    /* Center align the contents */
    width: 110px;
    /* Set the desired width for the cells */
}

.preview a {
    color: #795753;
    text-decoration: none;
}

.preview a:hover {
    color: #d6877d;
    text-decoration: none;
}

.textShadow {
    text-shadow: 2px 2px 2px #0000007e;
    /* Add a small text shadow */
}

.headerLabel {
    color: #8f8f8f;
}

.headerLabelPremium {
    color: #c49b67;
}

.available {
    color: #00ff00;
}

.unavailable {
    color: #6e6e6e;
}

.content p {
    margin-bottom: 20px;
    color: #bbb;
}

.content strong {
    color: #eee;
}

.upgrade-org-popup .button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 25px 25px 25px;
}

.upgrade-org-popup .resubscribe-button {
    margin-right: auto; /* Pushes the button to the left */
}

.upgrade-org-popup .other-buttons {
    display: flex;
    gap: 10px; /* Adds space between the right-aligned buttons */
}