.accountContainer {
    max-width: 900px;
    margin-top: 75px;
    color: #888;
}

.myrow {
    margin-top: 30px;
}

.valuerow {
    padding: 3px 0;
    align-items: center;
}

h2 {
    font-size: 24px;
    color: #666;
}

.label {
    font-size: 16px;
    color: #ddd;
}

.value {
    font-size: 16px;
    color: #888;
    user-select: text;
}
