.weaponIcon {
  max-height: 40px;
  height: 2vh;
}

.weaponIconT {
  -webkit-filter: invert(0.8) sepia(1) saturate(8) hue-rotate(-53deg) brightness(1) drop-shadow(2px 2px 2px rgb(34, 34, 34));
  filter: invert(0.8) sepia(1) saturate(8) hue-rotate(-53deg) brightness(1) drop-shadow(2px 2px 2px rgb(34, 34, 34));
}

.weaponIconCT {
  -webkit-filter: invert(0.7) sepia(1) saturate(5) hue-rotate(170deg) brightness(1) drop-shadow(2px 2px 2px rgb(34, 34, 34));
  filter: invert(0.7) sepia(1) saturate(5) hue-rotate(170deg) brightness(1) drop-shadow(2px 2px 2px rgb(34, 34, 34));
}

.scrollableContainer {
  max-height: 98%; /* Full viewport height */
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.projectileTable {
  width: 100%;
  flex-grow: 1; /* Takes up available space */
  color: #bbb;
}

.totalCountsRow {
  border-top: 1px solid rgb(82, 82, 82) !important;
  color: #7c7c7c;
  padding-top: 3px;
}

.countNumber {
  color: #fff;
  padding-right: 2px;
}

.replayButtonRow {
  border-bottom: 0px solid black !important;
  color: #666;
  padding-top: 20px;
}

.infoFooter {
  border-bottom: 0px solid black !important;
  color: #666;
}

.projectileTable th, .projectileTable td {
  text-align: left; /* Align text to the left */
  padding-right: 10px;
  font-size: 14px;
}

.projectileTable th {
  color: #eee;
  border-bottom: 1px solid #444;
}

.projectileRow:hover {
  cursor: pointer;
}

.projectileRow:hover td {
  background-color: rgb(59, 82, 52);
  color: rgb(255, 255, 255);
}

.playerCell {
  min-width: 200px !important;
}

.centeredCell {
  text-align: center !important;
}

.playButton {
  color: rgb(133, 133, 133);
  cursor: pointer;
}

.playButton:hover {
  color: rgb(79, 255, 94);
  cursor: pointer;
}

.removeButton {
  color: rgb(184, 70, 70);
  cursor: pointer;
}

/* Additional styling as needed */
