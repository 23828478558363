.roundpickerToolbar {
  display: flex;
  flex-direction: row;
}

.roundWinT {
  color: rgba(255, 195, 111, 0.5) !important;
}

.roundWinT:hover {
  background-color: rgba(121, 83, 34, 1);
  color: rgba(255, 195, 111, 0.8) !important;
}

.roundSelectedT {
  background-color: rgba(121, 83, 34, 1) !important;
  color: rgba(255, 195, 111, 1) !important;
}

.roundWinCT {
  color: rgba(143, 221, 255, 0.5) !important;
}

.roundWinCT:hover {
  background-color: rgba(25, 80, 102, 1);
  color: rgba(143, 221, 255, 0.8) !important;
}

.roundSelectedCT {
  color: rgba(143, 221, 255, 1) !important;
  background-color: rgba(25, 80, 102, 1) !important;
}