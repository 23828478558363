.tableRowLight {
  background-color: #3a3a3a; /* Light background color */
}

.tableRowDark {
  background-color: #333333; /* Dark background color */
}

.tableRowMoney {
  background-color: #333333; /* Dark background color */
  line-height: 22px;
}

.tableRowFooter {
  padding-top: 10px;
  background-color: none;
  border: 0px solid rgb(0,0,0,0);
}

.footerCell {
  text-align: center; /* Center align the contents */
  width: 110px; /* Set the desired width for the cells */
  padding-top: 10px !important;
}

.upgradeButton {
  width: 100%;
  border: none;
}

.upgradeButtonBasic {
  background-color: #67c467;
}

.upgradeButtonPremium {
  background-color: #c49b67;
}

.upgradeButtonOrg {
  background-color: #87b1ff;
}

.upgradeButtonBasic:hover {
  background-color: #92ee92;
}

.upgradeButtonPremium:hover {
  background-color: #e9c99f;
}

.upgradeButtonOrg:hover,
.upgradeButtonOrg:active,
.upgradeButtonOrg:focus {
  background-color: #bad2ff;
}

.centeredCell {
  text-align: center; /* Center align the contents */
  vertical-align: middle;
  width: 125px; /* Set the desired width for the cells */
}

.preview {
  cursor: pointer;
  text-align: center; /* Center align the contents */
  width: 110px; /* Set the desired width for the cells */
}

.preview a {
  color: #795753;
  text-decoration: none;
}

.preview a:hover {
  color: #d6877d;
  text-decoration: none;
}

.textShadow {
  text-shadow: 2px 2px 2px #0000007e; /* Add a small text shadow */
}

.topHeaderLabel {
  color: #8f8f8f;
  border: none !important;
  margin: 0px !important;
  padding: 0px !important;
}

.headerLabel {
  color: #8f8f8f;
}

.headerLabelBasic {
  color: #67c467;
}

.headerLabelPremium {
  color: #c49b67;
}

.headerLabelOrg {
  color: #87b1ff;
}

.availableFree {
  color: #ccc;
}

.available {
  color: #ffc983;
}

.availableOrg {
  color: #87b1ff;
}

.semiAvailable {
  color: #286420;
}

.unavailable {
  color: #5a5a5a;
  opacity: 0;
}

.priceTable {
    margin-bottom: 5px;
}

.priceTable td {
    padding-top: 4px;
    padding-bottom: 4px;
}

.quote {
  -webkit-filter: drop-shadow(1px 1px 1px rgb(0, 0, 0, 0.8));
  filter: drop-shadow(1px 1px 1px rgb(0, 0, 0, 0.8));
  position: relative;
  left: -10px;
}

.signature {
    -webkit-filter: drop-shadow(1px 1px 1px rgb(0, 0, 0, 0.8));
    filter: drop-shadow(1px 1px 1px rgb(0, 0, 0, 0.8));
    position: relative;
    left: -10px;
}

.promo {
  -webkit-filter: drop-shadow(0px 0px 3px rgb(0, 0, 0, 0.33));
  filter: drop-shadow(0px 0px 3px rgb(0, 0, 0, 0.33));
}

.attaxLogo {
  width: 96px;
  height: 96px;
  margin-left: 30px;
}

.xenn {
  position: relative;
  top: -10px;
  width: 133px;
  height: 133px;
  filter: drop-shadow(3px 0px 5px rgb(0, 0, 0, 0.3));
}

@media (max-width: 700px) {
  .tableRowLight {
    background-color: #3a3a3a; /* Light background color */
    font-size: 12px;
    white-space: nowrap;
  }
  
  .tableRowDark {
    background-color: #333333; /* Dark background color */
    font-size: 12px;
    white-space: nowrap;
  }
  
  .tableRowMoney {
    background-color: #333333; /* Dark background color */
    line-height: 22px;
    font-size: 12px;
    white-space: nowrap;
  }

  .priceTable {
    margin-bottom: 5px;
  }

  .priceTable td {
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: 12px;
  }

  .attaxLogo {
    width: 96px;
    height: 96px;
    margin-left: 10px;
  }
  
  .xenn {
    width: 80px;
    height: 80px;
    margin-right: 4px;
  }

  .quote {
    -webkit-filter: drop-shadow(1px 1px 1px rgb(0, 0, 0, 0.8));
    filter: drop-shadow(1px 1px 1px rgb(0, 0, 0, 0.8));
    position: relative;
    left: 0px;
  }
  
  .signature {
      -webkit-filter: drop-shadow(1px 1px 1px rgb(0, 0, 0, 0.8));
      filter: drop-shadow(1px 1px 1px rgb(0, 0, 0, 0.8));
      position: relative;
      left: 0px;
  }
}