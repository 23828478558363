:root {
  --header-height: 50px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

nav {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: var(--header-height);
  background-color:rgb(24, 24, 24);
  border-bottom: 1px dashed #444;
  -webkit-filter: drop-shadow(0px 0px 20px rgb(20, 20, 20));
  filter: drop-shadow(0px 0px 20px rgb(20, 20, 20));
  z-index: 1;
}

div.twitter-button {
  margin-right: 12px;
}

div.discord-button {
  position: relative;
  top: -2px;
  margin-right: 12px;
}

.navbar-margin {
  margin-left: 15px;
}

.logo {
  font-weight: 300;
  font-family: 'Saira Semi Condensed';
  font-size: 18px;
  position: relative;
  top: 2px;
}

.logo-large {
  font-weight: 300;
  font-family: 'Saira Semi Condensed';
  font-size: 24px;
  position: relative;
  top: 5px;
}

.logo-icon {
  -webkit-filter: drop-shadow(1px 1px 1px rgb(0, 0, 0));
  filter: drop-shadow(1px 1px 1px rgb(0, 0, 0));
}

.logo-csgo {
  padding-left: 4px;
  color: #777777;
  -webkit-filter: drop-shadow(1px 1px 1px rgb(0, 0, 0));
  filter: drop-shadow(1px 1px 1px rgb(0, 0, 0));
}

.logo-lens {
  padding-left: 1px;
  color: #aceeff;
  opacity: 0.8;
}

.feedback-text-wrapper {
  display: inline;
  position: relative;
}


.feedback-text {
  position: relative;
  top: 1px;
  margin-right: 15px;
  color: #ccc;
}

.feedback-text-arrow {
  position: relative;
  top: 2px;
  margin-right: 15px;
}

.feedback-label {
  margin-left: 5px;
}

.heart-image {
  margin: 2px;
}

a.twitter-button {
  position: relative;
  height: 20px;
  box-sizing: border-box;
  background-color: #1a7ec0;
  color: #fff;
  border-radius: 9999px;
  font-weight: 500;
  cursor: pointer;
  font: normal normal normal 12px/18px 'Helvetica Neue',Arial,sans-serif;
  text-decoration: none;
  padding: 5px 12px 5px 12px;
}

a.discord-button {
  position: relative;
  height: 20px;
  box-sizing: border-box;
  background-color: #5865F2;
  color: #fff;
  border-radius: 9999px;
  font-weight: 500;
  cursor: pointer;
  font: normal normal normal 12px/18px 'Helvetica Neue',Arial,sans-serif;
  text-decoration: none;
  padding: 5px 12px 5px 12px;
}

footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  /* height: var(--footer-height); */
  background-color:rgb(24, 24, 24);
  -webkit-filter: drop-shadow(0px 0px 20px rgb(22, 22, 22));
  filter: drop-shadow(0px 0px 20px rgb(22, 22, 22));
  border-top: 1px dashed #444;
  color: #fff;
}

.voice-comm-downloader-wrapper {
  position: fixed;
  bottom: 110px;
  left: 0px;
  padding: 0 min(12px, 0.5vw);
  margin: 0 min(12px, 0.5vw);
  color: #675668;
}

.voice-comm-downloader-spinner {
  width: 16px;
  height: 16px;
  margin-right: 10px;
  color: #cb7cd1;
}

.arrowless-dropdown-toggle::after {
  display: none !important; 
}

.dropdown-item-small {
  padding-top: 0;
  padding-bottom: 0;
  margin: 0;
  font-size: 0.8rem;
}

.dropdown-item-roundnum {
  color: rgb(70, 167, 223);
}

.dropdown-item-side {
  color: gray;
}

.no-page-found {
  margin-top: 75px;
}

.filters-label {
  position: relative;
  top: 3px;
}

.match-table-container {
  max-width: 1024px;
  margin-top: 75px;
}

.match-table-date {
  min-width: 100px;
  color: rgb(99, 99, 99);
}

.match-table-time {
  min-width: 60px;
  color: rgb(61, 61, 61);
}
.match-table-time.match-table-stars-1,
.match-table-time.match-table-stars-2,
.match-table-time.match-table-stars-3,
.match-table-time.match-table-stars-4,
.match-table-time.match-table-stars-5 {
  color: rgb(77, 77, 77);
}

.match-table-date-minimal {
  position: relative;
  top: -3px;
  color: rgb(83, 83, 83);
  font-size: 11px;
}

.match-table-stars-1 .match-table-date-minimal,
.match-table-stars-2 .match-table-date-minimal,
.match-table-stars-3 .match-table-date-minimal,
.match-table-stars-4 .match-table-date-minimal,
.match-table-stars-5 .match-table-date-minimal {
  color: rgb(102, 102, 102);
}

.match-table-team-name-label {
  color: rgb(151, 151, 151);
}
.match-table-team-name-label:hover {
  color: white;
}

.match-table-team-link {
  text-decoration: none;
}

.match-table-team-link:hover {
  color: #fff;
}

.match-table-hover-icon {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.match-table-team-link:hover .match-table-hover-icon {
  opacity: 1;
}

.match-table-stars-1 .match-table-team-name-label,
.match-table-stars-2 .match-table-team-name-label,
.match-table-stars-3 .match-table-team-name-label,
.match-table-stars-4 .match-table-team-name-label,
.match-table-stars-5 .match-table-team-name-label {
  color: rgb(221, 221, 221);
  font-weight: bold;
  letter-spacing: 0.01em;
  -webkit-filter: drop-shadow(1px 1px 1px rgb(0, 0, 0));
  filter: drop-shadow(1px 1px 1px rgb(0, 0, 0));
}

.match-table-stars-1 .match-table-team-name-label:hover,
.match-table-stars-2 .match-table-team-name-label:hover,
.match-table-stars-3 .match-table-team-name-label:hover,
.match-table-stars-4 .match-table-team-name-label:hover,
.match-table-stars-5 .match-table-team-name-label:hover {
  color: white;
}

.match-table-item {
  border-bottom-color:rgb(46, 46, 46);
  padding: 5px !important;
}

.match-table-score {
  color: rgb(83, 83, 83);
}

.match-table-cs2 {
  padding-left: 20px !important;
}

.match-table-cs2-div {
  color: rgb(153, 128, 61);
  font-size: small;
  background-color: rgb(58, 49, 30);
  border-radius: 6px;
  padding: 0px 4px !important;
  -webkit-filter: drop-shadow(1px 1px 1px rgb(0, 0, 0, 0.33));
  filter: drop-shadow(1px 1px 1px rgb(0, 0, 0, 0.33));
}

.match-table-disabled {
  color: gray;
}

.match-table-team {
  color: rgb(177, 177, 177);
  white-space: nowrap;
  width: 30vw;
}

.match-table-date-split {
  padding-top: 40px !important;
}

.match-table-maps {
  min-width: 150px;
  color: rgb(99, 99, 99);
}

.match-table-map {
  filter: saturate(0.2) contrast(0.85) brightness(0.7);
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}
.match-table-map:hover {
  filter: saturate(1.0) contrast(1.0) brightness(1.0) !important;
  cursor: pointer;
}

.match-table-points {
  width: 10vw;
  min-width: 70px;
  white-space: nowrap;
  font-size: 16px;
}

.match-table-points-minimal {
  white-space: nowrap;
  font-size: 16px;
  width: 20px;
}

.match-table-event {
  width: 50vw;
  white-space: nowrap;
  font-size: 12px;
}

.match-table-replay {
  min-width: 100px;
  width: 100px;
}

.match-table-event {
  color: rgb(71, 71, 71) !important;
}

.match-datepicker {
  width: 7.5em;
  caret-color: transparent;
}

.teams-search-input {
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 6px;
  margin-bottom: 15px !important;
  min-width: 210px;
  width: 88%;
}

.events-search-input {
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 6px;
  margin-bottom: 15px !important;
  min-width: 250px;
  width: 95%;
}

.table>:not(:first-child) {
  border-top: 2px solid rgb(141, 141, 141);
}

.react-datepicker {
  background-color:rgb(19, 19, 19) !important;
}

.react-datepicker__day {
  color: gray !important;
}

.react-datepicker__day--selected {
  color: white !important;
}

.react-datepicker__day--keyboard-selected {
  color: white !important;
}

.react-datepicker__day-name {
  color: gray !important;
}

.react-datepicker__header {
  background-color:rgb(32, 32, 32) !important;
}

.react-datepicker__current-month {
  color: white !important;
}

.react-datepicker__navigation-icon {
  margin-top: 12px;
}

.react-datepicker__triangle {
  display: none;
}

.map-picker {
  width: 10em;
}

.round-picker {
  width: 10em;
}

.round-win-t {
  color: rgba(255, 195, 111, 0.5) !important;
}

.round-win-t:hover {
  background-color: rgb(78, 63, 42, 0.6);
  color: rgba(255, 195, 111, 1) !important;
}

.round-selected-t {
  background-color: rgb(78, 63, 42, 0.6) !important;
  color: rgba(255, 195, 111, 1) !important;
}

.round-selected {
  background-color: rgb(78, 78, 78) !important;
  color: rgba(175, 175, 175, 0.5) !important;
}

.round-win-ct {
  color: rgba(143, 221, 255, 0.5) !important;
}

.round-win-ct:hover {
  background-color: rgb(50, 79, 92, 0.6);
  color: rgba(143, 221, 255, 1) !important;
}

.round-selected-ct {
  color: rgba(143, 221, 255, 1) !important;
  background-color: rgb(50, 79, 92, 0.6) !important;
}

.playlist-picker-new-match {
  border-left: 1px solid rgb(94, 94, 94);
}

.playlist-picker-btn {
  border-left: 1px solid rgb(48, 48, 48);
}

.playlist-round-num {
  display: inline;
}

.playlist-team-icon {
  display: none;
}

.btn-analyse {
  background-color: rgb(194, 129, 25);
  border-color: rgb(194, 129, 25);
}

.btn-sm:hover .playlist-round-num {
  display: inline;
}

.btn-sm:hover .playlist-team-icon {
  display: none;
}

.btn-sm.playlist-picker-new-match:hover .playlist-round-num {
  display: inline;
}

.btn-sm.playlist-picker-new-match:hover .playlist-team-icon {
  display: none;
}

.btn-sm.playlist-picker-new-match .playlist-round-num {
  display: none;
}

.btn-sm.playlist-picker-new-match .playlist-team-icon {
  display: inline;
}

.picker-button:hover {
  color: white !important;
}

.not-available {
  color: gray;
  padding-right: 25px;
  padding-left: 0px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.loading-label {
  position: relative;
  top: -8px;
  left: 12px;
}

.vertical-center-wrapper {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.horizontal-center-wrapper {
  margin: 0;
  position: absolute;
  left: 50%;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

@media (min-width: 1024px) {
  .player-info-loading {
    display: flex;
  }
}
@media (max-width: 1024px) {
  .player-info-loading {
    display: flex;
    height: 29.5px;
  }
}

@media (max-width: 1024px) {
  .player-info {
    border: 0 !important;
  }
}

@media (max-height: 1024px) {
  .player-info {
    font-size: small;
  }
}

.player-info-T {
  background-color: rgba(54, 45, 33, 0.5);
  color: rgb(255, 187, 98);
  border: 1px solid rgb(255, 175, 71, 0.25);
}

.player-info-hover-T {
  background-color: rgba(90, 68, 39, 0.5);
  color: rgb(255, 187, 98);
  border: 1px solid rgba(255, 175, 71, 0.308);
}

.player-info-CT {
  background-color: rgba(32, 51, 59, 0.5);
  color: rgb(71, 203, 255);
  border: 1px solid rgba(71, 181, 255, 0.25);
}

.player-info-hover-CT {
  background-color: rgba(45, 83, 99, 0.5);
  color: rgb(71, 203, 255);
  border: 1px solid rgba(71, 181, 255, 0.404);
}

.player-info-dead {
  background-color: rgba(48, 48, 48, 0.5);
  color: rgb(70, 70, 70);
  border: 1px solid rgba(46, 46, 46, 0.25);
}
.player-info-hover-dead {
  background-color: rgba(61, 61, 61, 0.5);
  color: rgb(70, 70, 70);
  border: 1px solid rgba(46, 46, 46, 0.25);
}

@media (min-width: 1024px) {
.player-name {
  padding-bottom: 6px;
}
}
@media (max-width: 1024px) or (max-height: 1024px) {
  .player-name {
    padding-bottom: 0px;
    font-size: small;
  }
  .player-weapon {
    padding-bottom: 0px;
    font-size: small;
  }
}

.player-cash-T {
  color: rgb(184, 135, 73);
}

.player-cash-CT {
  color: rgb(63, 157, 194);
}

.player-cash-dead {
  color: rgb(70, 70, 70);
}

.team-info-link {
  text-decoration: none;
  color: #ddd;
}

.team-info-link:hover {
  color: #fff;
}

.team-info-hover-icon {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.team-info-link:hover .team-info-hover-icon {
  opacity: 1;
}

@media (max-height: 1024px) {
  .team-info {
    font-size: small !important;
    color: #ddd;
  }
}

.team-info-loading {
  background-color: rgba(48, 48, 48, 0.5);
  color: rgb(70, 70, 70);
  border: 1px solid rgba(46, 46, 46, 0.25);
}

@media (min-width: 1024px) {
  .team-info {
    position: relative;
    background-color: rgba(68, 68, 68, 0.5);
    border: 1px solid rgba(163, 163, 163, 0.25);
    display: flex;
    flex-direction: column;
    font-size: large;
    font-weight: bold;
    line-height: 24px;
    color: #ddd;
  }
  .info {
    position: relative;
    padding: min(12px, 0.5vw);
    margin: min(12px, 0.5vw);
    font-size: small;
    z-index: 1;
  }
  .analyse-info {
    position: relative;
    padding-top: min(6px, 0.35vw);
    padding-bottom: min(6px, 0.35vw);
    padding-left: min(6px, 0.35vw);
    padding-right: min(6px, 0.35vw);
    margin: min(12px, 0.5vw);
    font-size: small;
    z-index: 1;
  }
} 
@media (max-width: 1024px) {
  .team-info {
    border-top: 1px solid rgba(163, 163, 163, 0.25);
    position: relative;
    display: flex;
    flex-direction: column;
    font-size: small;
    font-weight: bold;
    min-height: 37px;
    line-height: 10px;
    color: #ddd;
  }
  .info {
    position: relative;
    padding: 1px;
    z-index: 1;
  }
  .analyse-info {
    position: relative;
    padding-top: min(6px, 0.35vw);
    padding-bottom: min(6px, 0.35vw);
    padding-left: min(6px, 0.35vw);
    padding-right: min(6px, 0.35vw);
    margin: min(12px, 0.5vw);
    font-size: small;
    z-index: 1;
  }
} 

.team-info-wrapper {
  display: flex;
  flex-direction: row;
  user-select: none;
}

.team-buytype {
  background-color: rgba(17, 17, 17, 0.5);
  font-size: small;
  text-align: center;
  margin-top: 10px;
}

.healthbar-minimal {
  position: absolute;
  top: 0;
}

.armorbar {
  margin-top: 1px;
  height: 3px;
  width: 100%;
  border-radius: 2px;
}

.healthbar {
  height: 10px;
  width: 100%;
  border-radius: 2px;
}

.healthbar-T {
  background-color: rgb(112, 77, 30);
}

.healthbar-CT {
  background-color: rgb(30, 85, 107);
}

.healthbar-bg-T {
  background-color: rgb(255, 175, 71);
  -webkit-filter: drop-shadow(1px 1px 1px rgb(80, 80, 80));
  filter: drop-shadow(1px 1px 1px rgb(80, 80, 80));
}

.healthbar-bg-CT {
  background-color: rgb(71, 203, 255);
  -webkit-filter: drop-shadow(1px 1px 1px rgb(68, 68, 68));
  filter: drop-shadow(1px 1px 1px rgb(68, 68, 68));
}

.healthbar-dead {
  background-color: rgb(53, 53, 53);
}

.armor-icon {
  opacity: 0.5;
  font-size: calc(1.2vh + 6px);
  margin-right: 6px;
  position: relative;
  top: 0.5vh;
}

.weapon-icon {
  padding-top: 0.7vh;
  max-height: 40px;
  height: 3vh;
}

.weapon-icon-T {
  -webkit-filter: invert(0.8) sepia(1) saturate(8) hue-rotate(-53deg) brightness(0.5) drop-shadow(2px 2px 2px rgb(34, 34, 34));
  filter: invert(0.8) sepia(1) saturate(8) hue-rotate(-53deg) brightness(0.5) drop-shadow(2px 2px 2px rgb(34, 34, 34));
}

.weapon-icon-CT {
  -webkit-filter: invert(0.7) sepia(1) saturate(5) hue-rotate(170deg) brightness(0.5) drop-shadow(2px 2px 2px rgb(34, 34, 34));
  filter: invert(0.7) sepia(1) saturate(5) hue-rotate(170deg) brightness(0.5) drop-shadow(2px 2px 2px rgb(34, 34, 34));
}

.bomb-svg {
  -webkit-filter: invert(1.0) sepia(1) saturate(100) hue-rotate(-53deg) brightness(0.8);
  filter: invert(1.0) sepia(1) saturate(100) hue-rotate(-53deg) brightness(0.8);
}

.bomb-svg-shadow {
  opacity: 0.33;
}

.projectile-svg-T {
  -webkit-filter: invert(0.8) sepia(1) saturate(8) hue-rotate(-57deg) saturate(2) brightness(0.85);
  filter: invert(0.8) sepia(1) saturate(8) hue-rotate(-57deg) saturate(2) brightness(0.85);
}

.projectile-svg-CT {
  -webkit-filter: invert(0.7) sepia(1) saturate(5) hue-rotate(170deg) brightness(0.8);
  filter: invert(0.7) sepia(1) saturate(5) hue-rotate(170deg) brightness(0.8);
}

.projectile-svg-dimmed {
  opacity: 0.33;
}

.projectile-svg-shadow {
  opacity: 0.33;
}

.map-background {
  height: 100%;
  width: 100%;
}

.map-view-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.map-view-image-fullbright {
  position: absolute;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  opacity: 1
}

.map-view-image-dimmed {
  position: absolute;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  opacity: 0.15
}

.map-view-shadows {
  -webkit-filter: drop-shadow(3px 3px 3px rgb(31, 31, 31));
  filter: drop-shadow(3px 3px 3px rgb(31, 31, 31));
}

.map-view-svg {
  position: absolute;
  width: 100%;
  height: 100%;
}

.map-view-paintings {
  -webkit-filter: drop-shadow(3px 3px 3px rgb(0, 0, 0.1));
  filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.1));
}

.kill-feed-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 1.5vh;
  padding-right: 10px;
  font-size: 15px;
}

.kill-feed-weapon-icon {
  padding-left: 10px;
  padding-right: 3px;
  max-height: 40px;
  height: 2.5vh;
  -webkit-filter: invert(0.7) drop-shadow(2px 2px 2px rgb(20, 20, 20));
  filter: invert(0.7) drop-shadow(2px 2px 2px rgb(20, 20, 20));
}

.kill-feed-table td {
  min-width: 100px;
}

.kill-feed-player-T {
  color: rgb(230, 165, 81);
}

.kill-feed-player-CT {
  color: rgb(70, 188, 235);
}

.range-slider__wrap {
  width: 100%;
  margin-left: 0px;
  margin-right: 0px;
}

.range-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 1px solid #000000;
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: #ffffff;
  cursor: pointer;
  margin-top: -14px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
  -webkit-filter: drop-shadow(0px 0px 2px rgb(0, 0, 0));
  filter: drop-shadow(0px 0px 2px rgb(0, 0, 0));
}

.range-slider::-webkit-slider-runnable-track {
  width: 100%;
  cursor: pointer;
  height: 2px !important;
  -webkit-filter: drop-shadow(0px 0px 2px rgb(0, 0, 0));
  filter: drop-shadow(0px 0px 2px rgb(0, 0, 0));
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: #367ebd;
}

.round-picker-button {
  outline: none !important;
  box-shadow: none !important;
  -webkit-appearance: none !important;
}

.dropdown-round-picker-button button {
  min-height: 36px;
  min-width: 36px;
  white-space: nowrap;
  border: 0 !important;
  display: inline-block;
  color: lightgreen !important;
}


.dropdown-playlist-star-button button {
  min-height: 36px;
  min-width: 36px;
  white-space: nowrap;
  border: 0 !important;
  display: inline-block;
  color: rgb(160, 160, 160);
}

.round-timer {
  padding-top: 6px;
  padding-left: 10px;
}

.dropdown-toggle::after {
  display: none !important; 
}

.dropdown-item {
  padding-top: 0;
  padding-bottom: 0;
  margin: 0;
}

.playButton {
  border: 0 !important;
  color: lightgreen !important;
}

.speedButton {
  border: 0 !important;
  color: rgb(110, 110, 110) !important;
  margin-right: 10px;
}

.speedButton:hover {
  border: 0 !important;
  color: white !important;
}

.hotkeys-label {
  position: relative;
  top: 2px;
  margin-right: 20px;
  margin-left: 20px;
  color: rgb(73, 73, 73);
  cursor: pointer;
}

.hotkeys-popover {
  margin: 20px;
}

.matches-filter-container-sm {
  margin: 0px;
}

.matches-filter-button,
.matches-filter-button:focus {
    background-color: rgb(34, 34, 34);
    border-color: #555;
    padding: 6px 13px 6px 13px;
}

.matches-filter-button-sm,
.matches-filter-button-sm:focus {
    background-color: rgb(34, 34, 34);
    border-color: #555;
    font-size: 14px;
}

.matches-filter-col-sm {
  padding-right: 0px;
}

td.hotkey-space {
  padding-right: 20px;
  font-size: small;
  text-align: center;
}

td.hotkey {
  font-weight: bold;
  padding-right: 20px;
  text-align: center;
}

td.hotkey-arrow {
  position: relative;
  left: -3px;
  text-align: center;
  font-weight: bold;
  padding-right: 10px;
}

td.hotkey-description {
  color: gray;
}

@media (min-width: 1024px) {
  .main {
    position: fixed;
    top: var(--header-height);
    /* bottom: var(--footer-height); */
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
  }
  .map {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 60vw;
    top: 0;
    bottom: 0;
    align-items: center;
    justify-content: center;
  }
  .team {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 200px;
    max-width: 320px;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    margin-right: 10px;
  }
  .teaminfo {
    margin: 5px 0;
    min-height: 50px;
    max-height: 100px;
  }
  .playerinfo {
    margin: 5px 0;
    min-height: 50px;
    max-height: 100px;
  }
  .kill-feed {
    position: absolute;
    right: 0px;
    top: 0px;
  }
  .gif-record-element {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .dropdown-menu {
    max-height: 60vh;
    max-width: 60vw;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-filter: drop-shadow(0px 15px 20px rgb(24, 24, 24));
    filter: drop-shadow(0px 15px 20px rgb(24, 24, 24));
    border: 1px solid rgb(116, 116, 116);
    border-radius: 5px;
  }
}
@media (max-width: 1024px) {
  .main {
    position: fixed;
    top: var(--header-height);
    /* bottom: var(--footer-height); */
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
  }
  .map {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    align-items: center;
    justify-content: center;
  }
  .team {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .teams {
    display: flex;
    flex-direction: row;
  }
  .teaminfo {
    margin: 5px 0;
    width: 100%;
    min-height: 50px;
  }
  .playerinfo {
    margin: 5px 0;
    width: 100%;
    min-height: 50px;
  }
  .gif-record-element {
    position: absolute;
    top: -20px;
    left: -5vw;
    width: 110vw;
    height: calc(100vh - 300px);
  }
  .dropdown-menu {
    position: fixed !important;
    top: 0 !important;
    left: -5px !important;
    width: calc(100vw - 25px);
    height: calc(100vh - 150px);
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-filter: drop-shadow(0px 15px 20px rgb(24, 24, 24));
    filter: drop-shadow(0px 15px 20px rgb(24, 24, 24));
    border: 1px solid rgb(116, 116, 116);
    border-radius: 5px;
  }
}

.footer-flex {
  display: flex;
  flex-direction: column;
  align-items: left;
}

.gif-recorder-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* Add any desired styles for the overlay */
}

.record-button,
.record-button:focus,
.record-button:active {
  background-color: #404040;
}

.record-button:hover {
  background-color: #505050;
}

.borderless-dropdown-button .dropdown-toggle {
  border: none !important;
}


*:hover,
*:focus,
*:active,
*:checked,
*.hover,
*.focus,
*.active,
*.checked
{
  outline: none !important;
  box-shadow: none !important;
  -webkit-appearance: none !important;
}
