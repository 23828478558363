
.loadingLabel {
  position: relative;
  color: #666;
}

.spinnerLabel {
  position: relative;
  left: 6px;
  color: #666;
}


:root {
  --externalPlaylistLoaderProgressBarDarker: rgb(63, 74, 109);
  --externalPlaylistLoaderProgressBarLighter: rgb(72, 85, 114);
}

:global .externalPlaylistLoaderProgressBar .progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    var(--externalPlaylistLoaderProgressBarDarker) 25%,  
    var(--externalPlaylistLoaderProgressBarLighter) 25%, 
    var(--externalPlaylistLoaderProgressBarLighter) 50%, 
    var(--externalPlaylistLoaderProgressBarDarker) 50%,  
    var(--externalPlaylistLoaderProgressBarDarker) 75%, 
    var(--externalPlaylistLoaderProgressBarLighter) 75%,  
    var(--externalPlaylistLoaderProgressBarLighter)      
  ) !important;
}


:global .externalPlaylistLoaderProgressBar.progress {
  background-color: #2b2b2b !important;
}


:global .externalPlaylistLoaderProgressBar .progress-bar {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
  background-color: #293029 !important;
  color: #e6e6e6 !important;
}
