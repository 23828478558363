/* Info.module.css */

.termsContainer {
  max-width: 800px;
  margin-top: 90px;
}

.title {
  color: #cccccc;
  font-size: 2em;
  margin-bottom: 20px;
  margin-top: 20px;
}

.subtitle {
  color: #dbdbdb;
  font-size: 1.5em;
  margin-bottom: 20px;
  margin-top: 20px;
}

.termsContainer p {
  color: #9b9b9b;
  font-size: 1em;
  line-height: 1.5em;
  margin-bottom: 20px;
}