.waveformContainer {
  position: absolute;
  top: 0;
  left: 0.5%;
  right: 0.5%;
  bottom: 0;
  pointer-events: none;
  z-index: -1;
  width: 99%;
  height: 100%;
}

