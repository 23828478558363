.toggleButton
{
  font-size: 14px;
  border-color: #555;
  color: #555;
  width: 50%;
  outline: none !important;
  box-shadow: none !important;
}
.toggleButton:active {
  background-color: #3d3d3d !important;
  color: #ffffff !important;
  border-color: #b1b1b1 !important;
}
.toggleButton:hover
{
  color: #fff;
}

.toggleButtonSmall
{
  font-size: 10px;
  border-color: #555;
  color: #555;
  width: 50%;
  padding-left: 0px;
  padding-right: 0px;
  outline: none !important;
  box-shadow: none !important;
}
.toggleButtonSmall:active {
  background-color: #3d3d3d !important;
  color: #ffffff !important;
  border-color: #b1b1b1 !important;
}
.toggleButtonSmall:hover
{
  color: #fff;
}

.roundsContainer {
  max-width: 900px;
  margin-top: 75px;
}

.buttonGroupFullWidth {
  width: 100%;
  display: flex;
  margin-top: 15px;
}

.buttonGroupSmall {
  display: flex;
  margin-top: 15px;
}

.gridRow {
  width: 100%;
}

.tableRow {
  padding-top: 2px;
  padding-bottom: 1px;
}

.unselectedTableRow {
  padding-top: 2px;
  padding-bottom: 1px;
  color: #444 !important;
}

tr:last-child td.tableRow {
  padding-bottom: 8px;
}

tfoot td.tableRow {
  padding-top: 8px;  /* Or whatever value you want */
}

.teamFilterButton,
.teamFilterButton:focus {
    background-color: rgb(34, 34, 34);
    border-color: #555;
    padding: 6px 13px 6px 13px;
}

.resultsWrapper {
  width: 100%;
  margin-bottom: 15px;
}

.results {
  width: 100%;
}

.noresults {
  color: #424242;
}

.teamLabel {
  color: #888;
}

.teamLabelUnselected {
  color: #444;
}

.Eco:hover {
  background-color: rgb(80, 72, 46, 0.25);
  border-color: rgb(80, 72, 46);
}
.EcoSelected {
  color: #ffffff !important;
  background-color: rgb(80, 72, 46) !important;
  border-color: rgb(80, 72, 46) !important;
}

.Ecoplus:hover {
  background-color: rgb(91, 92, 46, 0.25);
  border-color: rgb(91, 92, 46);
}
.EcoplusSelected {
  color: #ffffff !important;
  background-color: rgb(91, 92, 46) !important;
  border-color: rgb(91, 92, 46) !important;
}

.Semi:hover {
  background-color: rgb(84, 107, 53, 0.25);
  border-color: rgb(84, 107, 53);
}
.SemiSelected {
  color: #ffffff !important;
  background-color: rgb(84, 107, 53) !important;
  border-color: rgb(84, 107, 53) !important;
}

.Full:hover {
  background-color: rgb(56, 116, 59, 0.25);
  border-color: rgb(56, 116, 59);
}
.FullSelected {
  color: #ffffff !important;
  background-color: rgb(56, 116, 59) !important;
  border-color: rgb(56, 116, 59) !important;
}

.T:hover {
  background-color: rgb(183, 119, 63, 0.25);
  border-color: rgb(127, 93, 49);
}
.TSelected {
  color: #ffffff !important;
  background-color: rgb(127, 93, 49, 1.0) !important;
  border-color: rgb(127, 93, 49) !important;
}

.CT:hover {
  background-color: rgb(34, 105, 122, 0.25);
  border-color: rgb(34, 105, 122);
}
.CTSelected {
  color: #ffffff !important;
  background-color: rgb(34, 105, 122) !important;
  border-color: rgb(34, 105, 122) !important;
}

.W:hover {
  background-color: rgba(56, 116, 59, 0.25);
  border-color: rgb(56, 116, 59);
}
.WSelected {
  color: #ffffff !important;
  background-color: rgb(56, 116, 59) !important;
  border-color: rgb(56, 116, 59) !important;
}

.L:hover {
  background-color: rgba(145, 38, 38, 0.25);
  border-color: rgb(145, 38, 38);
}
.LSelected {
  color: #ffffff !important;
  background-color: rgb(145, 38, 38) !important;
  border-color: rgb(145, 38, 38) !important;
}

.Ancient:hover {
  background-color: rgba(58, 63, 71, 0.25);
  border-color: rgb(58, 63, 71);
}
.AncientSelected {
  color: #ffffff !important;
  background-color: rgb(58, 63, 71) !important;
  border-color: rgb(58, 63, 71) !important;
}

.Anubis:hover {
  background-color: rgba(58, 63, 71, 0.25);
  border-color: rgb(58, 63, 71);
}
.AnubisSelected {
  color: #ffffff !important;
  background-color: rgb(58, 63, 71) !important;
  border-color: rgb(58, 63, 71) !important;
}

.Inferno:hover {
  background-color: rgba(58, 63, 71, 0.25);
  border-color: rgb(58, 63, 71);
}
.InfernoSelected {
  color: #ffffff !important;
  background-color: rgb(58, 63, 71) !important;
  border-color: rgb(58, 63, 71) !important;
}

.Dust:hover {
  background-color: rgba(58, 63, 71, 0.25);
  border-color: rgb(58, 63, 71);
}
.DustSelected {
  color: #ffffff !important;
  background-color: rgb(58, 63, 71) !important;
  border-color: rgb(58, 63, 71) !important;
}

.Train:hover {
    background-color: rgba(58, 63, 71, 0.25);
    border-color: rgb(58, 63, 71);
  }
.TrainSelected {
    color: #ffffff !important;
    background-color: rgb(58, 63, 71) !important;
    border-color: rgb(58, 63, 71) !important;
}
  
.Mirage:hover {
  background-color: rgba(58, 63, 71, 0.25);
  border-color: rgb(58, 63, 71);
}
.MirageSelected {
  color: #ffffff !important;
  background-color: rgb(58, 63, 71) !important;
  border-color: rgb(58, 63, 71) !important;
}

.Nuke:hover {
  background-color: rgba(58, 63, 71, 0.25);
  border-color: rgb(58, 63, 71);
}
.NukeSelected {
  color: #ffffff !important;
  background-color: rgb(58, 63, 71) !important;
  border-color: rgb(58, 63, 71) !important;
}

.Overpass:hover {
  background-color: rgba(58, 63, 71, 0.25);
  border-color: rgb(58, 63, 71);
}
.OverpassSelected {
  color: #ffffff !important;
  background-color: rgb(58, 63, 71) !important;
  border-color: rgb(58, 63, 71) !important;
}

.Vertigo:hover {
  background-color: rgba(58, 63, 71, 0.25);
  border-color: rgb(58, 63, 71);
}
.VertigoSelected {
  color: #ffffff !important;
  background-color: rgb(58, 63, 71) !important;
  border-color: rgb(58, 63, 71) !important;
}

.Month:hover {
  background-color: rgba(58, 63, 71, 0.25);
  border-color: rgb(58, 63, 71);
}
.MonthSelected {
  color: #ffffff !important;
  background-color: rgb(58, 63, 71) !important;
  border-color: rgb(58, 63, 71) !important;
}
.Months:hover {
  background-color: rgba(58, 63, 71, 0.25);
  border-color: rgb(58, 63, 71);
}
.MonthsSelected {
  color: #ffffff !important;
  background-color: rgb(58, 63, 71) !important;
  border-color: rgb(58, 63, 71) !important;
}
.Alltime:hover {
  background-color: rgba(58, 63, 71, 0.25);
  border-color: rgb(58, 63, 71);
}
.AlltimeSelected {
  color: #ffffff !important;
  background-color: rgb(58, 63, 71) !important;
  border-color: rgb(58, 63, 71) !important;
}

/* rounded corners, hover as link */
.premiumLink {
  border-radius: 10px;
  border: 1px solid #ffffff10;
  background-color: rgba(58, 69, 78, 0.5);
  color: #ffffff49;
  padding: 2px 10px 2px 10px;
  text-decoration: none;
  font-size: 0.8em;
  -webkit-filter: drop-shadow(1px 1px 1px rgb(0, 0, 0, 0.5));
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.5));
}

:root {
  --roundFilterProgressBarDarker: rgb(45, 53, 78);
  --roundFilterProgressBarLighter: rgb(51, 60, 80);
}

:global .roundFilterProgressBar .progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    var(--roundFilterProgressBarDarker) 25%,  
    var(--roundFilterProgressBarLighter) 25%, 
    var(--roundFilterProgressBarLighter) 50%, 
    var(--roundFilterProgressBarDarker) 50%,  
    var(--roundFilterProgressBarDarker) 75%, 
    var(--roundFilterProgressBarLighter) 75%,  
    var(--roundFilterProgressBarLighter)      
  ) !important;
}


:global .roundFilterProgressBar.progress {
  background-color: #2b2b2b !important;
}


:global .roundFilterProgressBar .progress-bar {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
  background-color: #293029 !important;
  color: #777777 !important;
}
