.container {
  max-width: 600px;
  margin: 100px auto;
  padding: 0 20px;
}

.title {
  font-size: 2rem;
  margin-bottom: 1.5rem;
  text-align: center;
}

.title i {
  margin-right: 0.5rem;
}

.form {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.formGroup {
  display: flex;
  flex-direction: column;
}

.formGroup label {
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.formGroup input {
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.formGroup input:disabled {
  background-color: #f0f0f0;
  cursor: not-allowed;
}

.submitButton {
  margin-top: 1rem;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.submitButton:hover {
  background-color: #0056b3;
}

.submitButton:disabled {
  background-color: #3a3a3a;
  color: #8d8d8d;
  cursor: not-allowed;
}

.submitButton:disabled:hover {
  background-color: #8d8d8d;
}

.explanation {
  margin-bottom: 2rem;
  color: #888;
}

.explanation p {
  margin-bottom: 1rem;
  line-height: 1.5;
}

.staticField {
  padding: 0.5rem;
  font-size: 1rem;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 4px;
  display: inline-block;
}

.fieldCaption {
  display: block;
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: #666;
}

.adminEmail {
  display: block;
  font-size: 1rem;
  color: #80ccff; /* A different color to make it stand out */
  margin-top: 0.25rem;
}

.price {
  color: #80ccff;
}

.spinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.loadingText {
  margin-left: 0.5rem;
}

.buttonSpinner {
  margin-right: 0.5rem;
}

.errorMessage {
  color: #dc3545;
  margin-top: 1rem;
  text-align: center;
}

.statusMessage {
  margin-top: 20px;
}

.centerAligned {
  text-align: center;
}

.processingMessage,
.successMessage {
  margin-bottom: 10px;
}

.processingStatus {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  color: #6db4ff;
}

.processingSpinner {
  margin-right: 10px;
}

.successMessage {
  font-weight: bold;
  color: #28a745;  /* or any color that fits your design */
}
