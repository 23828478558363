.eseaButton {
  padding: 4px 8px;
  line-height: 20px;
  color: rgb(51, 109, 51);
  border-color: rgb(51, 109, 51);
  text-align: left;
  font-size: 14px;
}

.eseaButton:hover {
  padding: 4px 8px;
  line-height: 20px;
  color: #ddd;
  border-color: rgb(51, 109, 51);
  background-color: rgb(51, 109, 51);
}

.userUploadButton {
  padding: 4px 8px;
  line-height: 20px;
  color: rgb(151, 78, 121);
  border-color: rgb(151, 78, 121);
  text-align: left;
  font-size: 14px;
}

.userUploadButton:hover {
  line-height: 20px;
  color: #ddd;
  border-color: rgb(151, 78, 121);
  background-color: rgb(151, 78, 121);
}

.hltvButton {
  padding: 4px 8px;
  line-height: 20px;
  text-align: left;
  font-size: 14px;
}

.noStatsButton,
.noStatsButton:hover {
  padding: 4px 8px;
  line-height: 20px;
  color: rgb(70, 70, 70);
  border: 1px dashed rgb(70, 70, 70);
  text-align: left;
  font-size: 14px;
  pointer-events: none;
}

