.mapSection {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 60vw;
  top: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
}

.menuSection {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 220px;
  max-width: 400px;
  margin-left: 20px;
  margin-right: 20px;
  align-items: center;
  margin-top: 30px;
  z-index: 10;
}

.menuRow {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 10px;
  color: #888;
}

.menuSubRow {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  color: #888;
}

.menuSubRow > *:not(:last-child) {
  border-bottom: none;
  margin-bottom: -1px;
}

.footerWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.infoTable {
  width: 100%;
}

.infoTableWrapper {
  width: 100%;
  min-width: 300px;
}

.infoTableHotkey {
  color: #aaa;
  text-align: right;
  padding-right: 15px;
}

.infoTableHotkeyDescription {
  color: #777;
}

.footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 54px;
  background-color:rgb(24, 24, 24);
  -webkit-filter: drop-shadow(0px 0px 20px rgb(22, 22, 22));
  filter: drop-shadow(0px 0px 20px rgb(22, 22, 22));
  border-top: 1px dashed #444;
  color: #fff;
}

.projectileIcon {
  width: 24px;
  height: 24px;
  -webkit-filter: drop-shadow(1px 1px 1px rgb(0, 0, 0, 0.5));
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.5));
}

.progressBarWrapper {
  width: 100%;
  align-self: self-end;
}

.betaTest {
  color: #858584;
  background-color: rgb(46, 45, 38);
  border: 1px dashed rgb(107, 101, 18);
  padding: 7px 20px 7px 20px;
  text-align: center;
  border-radius: 8px;
  font-size: 14px;
  width: 100%;
}

.betaTest a {
  text-decoration: none;
}

:root {
  --analysisProgressBarDarker: rgb(57, 65, 99);
  --analysisProgressBarLighter: rgb(70, 80, 107);
  --analysisProgressBarDoneDarker: rgb(42, 59, 44);
  --analysisProgressBarDoneLighter: rgb(42, 59, 44);
}

:global .analysisProgressBar .progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    var(--analysisProgressBarDarker) 25%,  
    var(--analysisProgressBarLighter) 25%, 
    var(--analysisProgressBarLighter) 50%, 
    var(--analysisProgressBarDarker) 50%,  
    var(--analysisProgressBarDarker) 75%, 
    var(--analysisProgressBarLighter) 75%,  
    var(--analysisProgressBarLighter)      
  ) !important;
}


:global .analysisProgressBar.progress {
  background-color: #2b2b2b !important;
}


:global .analysisProgressBar .progress-bar {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
  background-color: #293029 !important;
  color: #e6e6e6 !important;
}

:global .analysisProgressBarDone .progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    var(--analysisProgressBarDoneDarker) 25%,  
    var(--analysisProgressBarDoneLighter) 25%, 
    var(--analysisProgressBarDoneLighter) 50%, 
    var(--analysisProgressBarDoneDarker) 50%,  
    var(--analysisProgressBarDoneDarker) 75%, 
    var(--analysisProgressBarDoneLighter) 75%,  
    var(--analysisProgressBarDoneLighter)      
  ) !important;
}


:global .analysisProgressBarDone.progress {
  background-color: #2b2b2b !important;
}


:global .analysisProgressBarDone .progress-bar {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
  background-color: #293029 !important;
  color: #e6e6e6 !important;
}