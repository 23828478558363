/* Info.module.css */

.container {
    max-width: 800px;
    margin-top: 90px;
}

.title {
    color: #8f8f8f;
    font-size: 2em;
    margin-bottom: 20px;
}

.subtitle {
    color: #dbdbdb;
    font-size: 1.5em;
    margin-bottom: 10px;
}

.text {
    color: #949494;
    font-size: 1em;
    line-height: 1.5em;
    margin-bottom: 20px;
}

.headerLabelOrg {
  color: #87b1ff;
}