/* UploadDropZone.module.css */

code {
    margin-left: 3px;
    margin-right: 3px;
}

p {
    margin-top: 0px;
    margin-bottom: 0px;
}

.dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center; /* align vertical */
    align-items: center; /* align horizontal */
    padding: 20px;
    border-width: 2px;
    border-radius: 5px;
    border-color: #555555;
    border-style: dashed;
    background-color: #222222;
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s ease-in-out;
    cursor: pointer;
    height: 80px;
}

.dropzone_disabled {
    cursor: not-allowed;
    border-color: #444 !important;
    color: #444 !important;
}

.dropzone:focus {
    border-color: #00ccff;
}

.dropzone.is-drag-active {
    border-color: #4aaec7;
    background-color: #262d33;
}

.error {
    color: #ff5555;
    margin-top: 10px;
}