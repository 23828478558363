.toggleButtonGroup {
    display: flex;
}

.buttonGroupFullWidth {
    width: 100%;
    display: flex;
}

.toggleButton {
    font-size: 14px;
    border-color: #555;
    color: #555;
    flex-grow: 1;
    outline: none !important;
    box-shadow: none !important;
}

.toggleButton:active {
    background-color: #3d3d3d !important;
    color: #ffffff !important;
    border-color: #b1b1b1 !important;
}

.toggleButton {
    width: 50%;
}

.toggleButtonColumn {
    width: 100%;
}

.toggleButtonColumn:not(:last-child) {
    border-bottom: none;
}

.toggleButtonSmall:active {
    background-color: #3d3d3d !important;
    color: #ffffff !important;
    border-color: #b1b1b1 !important;
}

.toggleButtonSmall:hover {
    color: #fff;
    border-color: #555 !important;
}

.toggleButton:hover {
    color: #fff;
    background-color: rgb(77, 77, 77) !important;
    border-color: #555 !important;
}

.selectedButton {
    color: #ffffff !important;
    background-color: rgb(58, 58, 58) !important;
    border-color: #555 !important;
}

.selectedButton:hover {
    color: #ffffff !important;
    background-color: rgb(77, 77, 77) !important;
    border-color: #555 !important;
}

.toggleButton:active, .selectedButton:active {
    color: #ffffff;
    background-color: rgb(100, 100, 100) !important;  /* Change to desired color */
    border-color: #555 !important;
}
