.notesContainer {
  background-color: #222;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1000;
}

.emptyState {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #777;
  cursor: pointer;
  padding: 4px;
  font-size: 14px;
}

.emptyState:hover {
  color: #aaa;
}

.noteHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0 4px;
}

.noteTime {
  color: #888;
  font-size: 12px;
  cursor: pointer;
  padding: 2px 4px;
  border-radius: 2px;
}

.noteTimeEditing {
  color: #affaaf;
}

.noteTime:hover {
  color: #fff;
}

.noteNavigation {
  display: flex;
  gap: 5px;
  align-items: center;
  margin-right: -8px;
}

.navButton {
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  padding: 2px 6px;
}

.navButton:hover {
  color: #fff;
}

.navButton:disabled {
  color: #444;
  cursor: default;
}

.notePosition {
  color: #888;
  font-size: 12px;
  min-width: 40px;
  text-align: center;
}

.noteContent {
  flex: 1;
  padding: 6px;
  color: #ddd;
  background-color: #202020;
  border-top: 1px solid #383838;
  border-bottom: 1px solid #383838;
  font-size: 14px;
  overflow-y: auto;
  cursor: pointer;
}

.noteContent:hover {
  background-color: #252525;
}

.noteContent::-webkit-scrollbar {
  width: 6px;
}

.noteContent::-webkit-scrollbar-track {
  background: transparent;
}

.noteContent::-webkit-scrollbar-thumb {
  background: #444;
  border-radius: 3px;
}

.noteContent::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.noteContentEditing {
  flex: 1;
  margin: 0px 0px;
}

.noteContentEditing textarea {
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
  color: #fff;
  background-color: #1a1a1a;
  border-top: 1px solid #383838;
  border-bottom: 1px solid #383838;
  padding: 6px;
  resize: none;
  font-size: 14px;
  overflow-y: auto;
}

.noteContentEditing textarea::-webkit-scrollbar {
  width: 6px;
}

.noteContentEditing textarea::-webkit-scrollbar-track {
  background: transparent;
}

.noteContentEditing textarea::-webkit-scrollbar-thumb {
  background: #444;
  border-radius: 3px;
}

.noteContentEditing textarea::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.noteContentEditing textarea::placeholder {
  color: #444;
  font-style: italic;
}

.noteText {
  white-space: pre-wrap;
  word-break: break-word;
}

.noteFooter {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
  margin-right: -8px;
}

.footerButton {
  background: none;
  border: none;
  color: #555;
  cursor: pointer;
  padding: 4px 8px;
  font-size: 13px;
}

.footerButton:hover {
  color: #fff;
}

.footerButton.danger:hover {
  color: #ff4444;
}

.footerButton.primary {
  color: #555;
}

.noteContentEditing ~ .noteFooter .footerButton.primary {
  color: #5bc05b;
}

.footerButton.primary:hover {
  color: #66ff73;
}

.loadingState {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: #666;
  font-size: 14px;
  height: 100%;
}

.spinner {
  width: 16px;
  height: 16px;
  border: 2px solid #666;
  border-top-color: transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
