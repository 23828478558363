.modal {
  min-width: 800px;
}

.myrow {
  padding: 10px 0;
  font-size: 14px;
}

.currentTime {
  height: 100%;
  display: flex;
  align-items: center;
  color: #aaa;
  font-size: 1.15em;
}

.textInput {
  padding: 5px 13px 5px 13px;
  font-size: 14px;
}

.error {
  color: rgb(224, 57, 57);
  font-size: 12px;
  padding-top: 3px;
}

.statusGroup {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.statusItem {
  flex-grow: 1;
  padding: 5px;
  text-align: center;
  color: rgb(92, 92, 92);
  pointer-events: none;
  border-radius: 5px;
  border-color: #555 !important;
  width: 200px;
  font-size: 14px;
}

.volumeIcon {
  font-size: 1.5em;
}

.new.active {
  background-color: #252b33 !important; /* Dark, less saturated Blue */
}

.initializing.active {
  background-color: #204269 !important; /* Gradient from Blue */
}

.uploading.active {
  background-color: #374e6d !important; /* Gradient from Blue */
}

.queued.active {
  background-color: #524d29 !important; /* Gradient from Blue */
}

.parsing.active {
  background-color: #42665A !important; /* Gradient towards Green */
}

.waiting.active {
  background-color: #29524f !important; /* Gradient towards Green */
}

.processing.active {
  background-color: #524d29 !important; /* Gradient towards Green */
}

.loading.active {
  background-color: #5b385e !important; /* Gradient towards Green */
}

.sync.active {
  background-color: #1d5557 !important; /* Gradient towards Green */
}

.done.active {
  background-color: #2E8B57 !important; /* Dark, less saturated Green */
}

.uploadSpinner {
  color: #666;
  margin-right: 4px;
  width: 14px;
  height: 14px;
}

.spinnerInitializing {
  color: #3e83d1;
  width: 10px;
  height: 10px;
  position: relative;
  top: -2px;
}

.spinnerUploading {
  color: #71a3e4;
  width: 10px;
  height: 10px;
  position: relative;
  top: -2px;
}

.spinnerQueued {
  color: #e0d36f;
  width: 10px;
  height: 10px;
  position: relative;
  top: -2px;
}

.spinnerParsing {
  color: #84ceb5;
  width: 10px;
  height: 10px;
  position: relative;
  top: -2px;
}

.spinnerLoading {
  color: #f783ff;
  width: 10px;
  height: 10px;
  position: relative;
  top: -2px;
}

.spinnerProcessing {
  color: #e0d36f;
  width: 10px;
  height: 10px;
  position: relative;
  top: -2px;
}

.voiceButton {
  color: #f783ff;
  filter: drop-shadow(0 0 4px #f34aff);
  font-size: 1.15em;
}

.new.inactive,
.initializing.inactive,
.uploading.inactive,
.queued.inactive,
.parsing.inactive,
.waiting.inactive,
.processing.inactive,
.loading.inactive,
.sync.inactive,
.done.inactive {
  background-color: rgba(34, 34, 34, 0);
  color: rgb(85, 85, 85) !important;
}

:root {
  --uploadProgressBarDarker: #293a52;
  --uploadProgressBarLighter: #2d405a;
}
:global .uploadProgressBar .progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    var(--uploadProgressBarDarker) 25%,  
    var(--uploadProgressBarLighter) 25%, 
    var(--uploadProgressBarLighter) 50%, 
    var(--uploadProgressBarDarker) 50%,  
    var(--uploadProgressBarDarker) 75%, 
    var(--uploadProgressBarLighter) 75%,  
    var(--uploadProgressBarLighter)      
  ) !important;
}
:global .uploadProgressBar.progress {
  background-color: #2b2b2b !important;
}
:global .uploadProgressBar .progress-bar {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
  background-color: #293029 !important;
  color: #b6b6b6 !important;
}

:root {
  --processingProgressBarDarker: #444021;
  --processingProgressBarLighter: #4b4626;
}
:global .processingProgressBar .progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    var(--processingProgressBarDarker) 25%,  
    var(--processingProgressBarLighter) 25%, 
    var(--processingProgressBarLighter) 50%, 
    var(--processingProgressBarDarker) 50%,  
    var(--processingProgressBarDarker) 75%, 
    var(--processingProgressBarLighter) 75%,  
    var(--processingProgressBarLighter)      
  ) !important;
}
:global .processingProgressBar.progress {
  background-color: #2b2b2b !important;
}
:global .processingProgressBar .progress-bar {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
  background-color: #293029 !important;
  color: #b6b6b6 !important;
}

:root {
  --loadingProgressBarDarker: #5b385e;
  --loadingProgressBarLighter: #69406d;
}
:global .loadingProgressBar .progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    var(--loadingProgressBarDarker) 25%,  
    var(--loadingProgressBarLighter) 25%, 
    var(--loadingProgressBarLighter) 50%, 
    var(--loadingProgressBarDarker) 50%,  
    var(--loadingProgressBarDarker) 75%, 
    var(--loadingProgressBarLighter) 75%,  
    var(--loadingProgressBarLighter)      
  ) !important;
}
:global .loadingProgressBar.progress {
  background-color: #2b2b2b !important;
}
:global .loadingProgressBar .progress-bar {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
  background-color: #293029 !important;
  color: #b6b6b6 !important;
}