.info {
  position: relative;
  padding: min(12px, 0.25vw) 0px;
  margin: min(12px, 0.25vw);
  font-size: small;
  z-index: 1;
}

.infoMinimal {
  padding: 2px;
  margin: 1px 0;
  font-size: 0.8rem;
}

.playerInfo {
  margin: 0px 0;
  min-height: 50px;
  max-height: 100px;
  color: rgb(255, 255, 255);
}

.playerInfoMinimal {
  min-height: unset;
  max-height: unset;
}

.playerInfoDead {
  color: rgb(70, 70, 70);
}

.playerInfoDead .playerCash {
  color: rgb(70, 70, 70);
}

.playerName {
  padding-bottom: 0;
}

.playerCash {
  color: rgb(255, 255, 255);
  padding: 0 4px;
}

.armorIcon {
  opacity: 0.5;
  font-size: calc(1.1vh + 6px);
  margin-right: 6px;
  position: relative;
  top: 0.5vh;
  color: rgb(136, 136, 136);
}

.weaponIcon {
  padding-top: 0.7vh;
  max-height: 40px;
  height: 2.8vh;
}

.weaponIconNeutral {
  filter: invert(0.8) brightness(0.7) drop-shadow(2px 2px 2px rgb(22, 22, 22));
}

@media (max-width: 1024px) {
  .playerInfo {
    border: 0 !important;
  }
  
  .playerName {
    padding-bottom: 0px;
    font-size: small;
  }

}

@media (max-height: 1024px) {
  .playerInfo {
    font-size: small;
  }
}

.healthContainer {
  position: relative;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 4px;
  min-height: 24px;
  display: flex;
  align-items: center;
}

.healthBackground {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  transition: width 0.14s ease-out;
  border-radius: 4px;
}

.healthBackgroundT {
  background-color: rgb(121, 83, 34);
}

.healthBackgroundCT {
  background-color: rgb(25, 80, 102);
}

.contentContainer {
  position: relative;
  z-index: 1;
  width: 100%;
  padding: 0 8px;
}

.healthBackgroundLoading {
  background-color: #2b2b2b;
  opacity: 1.0;
}

.playerLoading {
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 4px;
  min-height: 24px;
  margin-bottom: 20px;
  width: 100%;
  min-width: 200px;
  max-width: 100%;
}

.loadingText {
  color: #666;
  font-size: 0.8rem;
  margin-left: 8px;
}

.contentContainerMinimal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 8px;
  position: relative;
  z-index: 2;
  width: 100%;
}

.playerNameCashContainer {
  display: flex;
  gap: 8px;
  align-items: center;
  min-width: 0;
}

.weaponsContainer {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-left: auto;
}

.playerInfoMinimal .playerName {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.playerWeaponMinimal {
  color: #888;
  font-size: 0.75rem;
}

.playerCashMinimal {
  color: #888;
  font-size: 0.75rem;
  padding: 0;
}

/* Add styles for minimal mode weapon icons */
.playerInfoMinimal .weaponIcon {
  padding-top: 0;
  height: 16px;
  vertical-align: middle;
  filter: invert(0.8) brightness(0.7) drop-shadow(1px 1px 1px rgb(0, 0, 0, 0.5));
}

.playerWeaponMinimal {
  color: #888;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  gap: 4px;
} 