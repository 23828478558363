.alertPopup {
  opacity: 0;
  animation: fadeIn 300ms forwards;
  filter: drop-shadow(0 0 0.5rem rgba(0, 0, 0, 0.5));
  border: 1px solid #484848;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.fadeOut {
  animation: fadeOut 300ms forwards;
}

@keyframes fadeOut {
  to {
    opacity: 0;
  }
}

.centerContent {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}