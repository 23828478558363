.playlistImport {
    margin-top: 20px;
  }
  
  .textInput {
    font-family: monospace;
    font-size: 1rem;
    padding: 10px;
  }
  
  .textarea {
    font-family: monospace;
    font-size: 0.85rem;
    padding: 10px;
  }

  .csvFormat {
    font-family: monospace;
    font-size: 0.8em;
    color: #aaa;
  }
  