.checkoutContainer {
  max-width: 900px;
  margin-top: 75px;
  font-size: 14px;
}

.checkoutContainer h1 {
  font-size: 24px;
  color: #ddd;
}

.checkoutContainer hr {
  padding: 0px;
  margin: 0px;
}

.myrow {
  color: #aaa;
  padding-top: 10px;
  padding-bottom: 10px;
}