
.teamFilterButton,
.teamFilterButton:focus {
    background-color: rgb(34, 34, 34);
    border-color: #555;
    padding: 6px 13px 6px 13px;
}

.mapFilterButton,
.mapFilterButton:focus {
    background-color: rgb(34, 34, 34);
    border-color: #555;
    padding: 6px 13px 6px 13px;
}

.checkbox {
  padding-right: 10px;
}

.matchDate {
  color: #555;
  max-width: 100px;
  min-width: 100px;
}

.loadingLabel {
  position: relative;
  left: 12px;
  color: #888;
}

.tableWrapper {
  background-color: #282828;
  border: 1px dashed #484848;
  padding: 6px 12px;
  margin-right: 25px;
  border-radius: 5px;
}

.table {
  background-color: #282828;
}

.table tr:last-child td {
  padding-bottom: 4px;
}

.footer {
  padding-top: 4px;
  border-top: 1px solid #444;
  color: #777;
}

.premiumLink {
  color: #ffffff49;
  padding: 0px 10px 0px 10px;
  text-decoration: none;
  font-size: 0.8em;
  -webkit-filter: drop-shadow(1px 1px 1px rgb(0, 0, 0, 0.5));
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.5));
}

.inlineFlexCenter {
  display: inline-flex;
  align-items: center;
}

.modal {
  max-width: 560px;
}