.info {
  position: relative;
  padding: min(12px, 0.15vw) 0;
  margin: min(12px, 0.15vw) 0;
  z-index: 1;
}

.teamInfo {
  position: relative;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
  color: #ddd;
}

.teamInfoWrapper {
  display: flex;
  flex-direction: row;
  user-select: none;
  margin: 0px !important;
}

.teamInfoLink {
  text-decoration: none;
  color: #ddd;
}

.teamInfoLink:hover {
  color: #fff;
}

.teamInfoHoverIcon {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.teamInfoLink:hover .teamInfoHoverIcon {
  opacity: 1;
}

@media (max-width: 1024px) {
  .teamInfo {
    border-top: 1px solid rgba(163, 163, 163, 0.25);
    position: relative;
    display: flex;
    flex-direction: column;
    font-size: small;
    font-weight: bold;
    min-height: 37px;
    line-height: 10px;
    color: #ddd;
  }

  .info {
    position: relative;
    padding: 1px;
    z-index: 1;
  }
}

@media (max-height: 1024px) {
  .teamInfo {
    color: #ddd;
  }
} 