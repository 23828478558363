.topview {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 34px;
}

.centered {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0px;
}

.clock {
  padding: 1px 14px;
  background-color:rgb(24, 24, 24);
  border-radius: 4px;
  border: 1px solid #444;
  font-size: 17px;
  z-index: 2;
  -webkit-filter: drop-shadow(2px 2px 2px rgb(0, 0, 0, 0.2));
  filter: drop-shadow(2px 2px 2px rgb(0, 0, 0, 0.2));
}
